import React from 'react';
import { Switch } from 'ui-kit/switch';
import { AutoRefillToggleProps } from './types';
import './index.style.scss';
import { Button } from 'react-bootstrap';
import RenewtIcon from 'ui-kit/icons/renew-icon/renew-icon';
import classNames from 'classnames';

export const AutoRefillToggle: React.FC<AutoRefillToggleProps> = ({
    checked,
    onChange,
    t,
    rxEligible,
    isBusy,
    isConsentExpiresSoon,
    isConsentExpired,
    isLabelVisible = true,
    className,
    isCaliforniaUser,
    isDisabled
}) => {
    const classes = classNames('auto-refill-toggle-container d-flex flex-column', className);

    return (
        <div className={classes}>
            {rxEligible ? (
                <>
                    <div className="d-flex align-items-end">
                        {isLabelVisible ? (
                            <div className="auto-refill-toggle-label">
                                <span>{t('components.autoRefillToggle.label')}</span>
                            </div>
                        ) : (
                            <></>
                        )}
                        <Switch checked={checked} onChange={onChange} disabled={isBusy || isDisabled} t={t} />
                    </div>
                    {isCaliforniaUser && isConsentExpiresSoon && !isConsentExpired && checked ? (
                        <div className="auto-refill-expires-soon">
                            <span className="text-warning font-weight-bolder">
                                {t('components.autoRefillToggle.autoRefillExpiresSoon')}
                            </span>
                            <Button
                                onClick={() => onChange(true, true)}
                                className="auto-refill-renew-link border-none no-min-width"
                                variant="link"
                            >
                                <RenewtIcon />
                                <span>{t('components.autoRefillToggle.renew')}</span>
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )}
                    {isCaliforniaUser && isConsentExpired ? (
                        <span className="auto-refill-expired text-danger">
                            {t('components.autoRefillToggle.autoRefillExpired')}
                        </span>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <span className="auto-refill-not-eligible">{t('components.autoRefillToggle.notEligible')}</span>
            )}
        </div>
    );
};
