import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// UI & Components
import PillBottleIcon from 'assets/icons/pillBottle.svg';
import PlaneIcon from 'assets/icons/plane.svg';
import CtaBlock, { CtaBlockButtonProps } from 'components/cta-block/CtaBlock';

// State management
import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';

import './quick-links.style.scss';

const QuickLinks = () => {
    const { t } = useTranslation();
    const { setPrescriptionFlowType } = useAddTransferPrescription();

    const handleTransferPrescriptionClick = React.useCallback<NonNullable<CtaBlockButtonProps['onClick']>>(() => {
        setPrescriptionFlowType({ flowType: 'Transfer' });
        navigate('/secure/prescription');
    }, [setPrescriptionFlowType]);

    const handleViewMedicineCabinetClick = React.useCallback<NonNullable<CtaBlockButtonProps['onClick']>>(() => {
        navigate('/secure/medicine-cabinet');
    }, []);

    return (
        <div className="quick-links">
            <div className="quick-links__row">
                <div className="quick-links__column">
                    <CtaBlock
                        className="quick-links__cta-block--button w-100"
                        iconContent={
                            <img
                                alt={t('pages.prescriptionConfirmation.cta.transferPrescription.imageAlt')}
                                src={PlaneIcon}
                            />
                        }
                        onClick={handleTransferPrescriptionClick}
                        variant="button"
                    >
                        {t('pages.prescriptionConfirmation.cta.transferPrescription.label')}
                    </CtaBlock>
                </div>
                <div className="quick-links__column">
                    <CtaBlock
                        className="quick-links__cta-block--button w-100"
                        iconContent={
                            <img
                                alt={t('pages.prescriptionConfirmation.cta.medicineCabinet.label')}
                                src={PillBottleIcon}
                            />
                        }
                        onClick={handleViewMedicineCabinetClick}
                        variant="button"
                    >
                        {t('pages.prescriptionConfirmation.cta.medicineCabinet.label')}
                    </CtaBlock>
                </div>
            </div>
        </div>
    );
};

export default QuickLinks;
